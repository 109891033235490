import { createStore } from 'vuex'
import ccenv from './modules/ccenv'
import getter from './getter'

export default createStore({
  state: {
    productIndex: 0
  },
  getters: {
    getProductIndex(state) {
      return state.productIndex
    }
  },
  mutations: {
    setProductIndex(state, index) {
      state.productIndex = index
    }
  },
  actions: {
  },
  modules: {
    ccenv
  },
  
})
