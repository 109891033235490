<template>
  <div class="header-boxs">
    <div class="header-left">
      <div class="left" />
      <div class="middle" @click="OnClick($event, 1)">
        <img src="../assets/images/log2.png" class="logo" alt="">
      </div>
      <div class="right" />
    </div>
    <div class="header-right">
      <div class="menu">
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClick($event, 1)">
          <h1 class="normal">HOME</h1>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave">
          <h1 class="normal">PRODUCTS</h1>
          <img src="../assets/images/pc/thr2.png" alt="" class="arrow">
          <div class="menuChild">
            <ul>
              <li @click="OnClick($event, 2, 0)">All</li>
              <li @click="OnClick($event, 2, 1)">Board Games</li>
              <li @click="OnClick($event, 2, 2)">Digital Games</li>
            </ul>
          </div>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClick($event, 3)">
          <h1 class="normal">NEWS</h1>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClick($event, 4)">
          <h1 class="normal">TEAM</h1>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="JumpContact()">
          <h1 class="normal">CONTACT</h1>
        </div>
      </div>
    </div>
<!--    <div class="header-right" v-else>-->
<!--      <div class="menu2">-->
<!--        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClickChild($event, level.slice(0, index + 1).join('/').replaceAll('.', ''))" v-for="(item, index) in level" :key="index">-->
<!--          <h1 class="normal">{{ item.toUpperCase().replaceAll('_', ' ').replaceAll('.', 'HOME') }}</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script setup lang="ts">
import { onMounted, defineEmits } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router"
const store = useStore();
const router = useRouter()
const emit = defineEmits(['clickMenu', 'clickChildMenu'])

const OnEnter = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('normal')
    event.target.children[0].classList.add('highlight')
  }
  event.target.classList.add('hover')
  event.target.classList.add('active')
}

const OnLeave = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('highlight')
    event.target.children[0].classList.add('normal')
  }
  event.target.classList.remove('hover')
  event.target.classList.remove('active')
}

const OnClick = (event, id, navIndex = 0) => {
  if(router.currentRoute.value.fullPath == '/'){
    // 首页直接跳转到锚点
    emit('clickMenu', id)
  }else {
    // 其他页面跳转到首页再定位锚点
    router.replace({
      path: '/index'
    })
    setTimeout(() => {
      emit('clickMenu', id)
    }, 400)
  }
  store.commit('setProductIndex', Number(navIndex))

}

const JumpContact = () => {
  router.push({
    path: '/contact'
  })
}

const OnClickChild = (event, uri) => {
  emit('clickChildMenu', uri)
}

const splitPath = () => {
  // console.log('window.location.pathname =====>' + window.location.pathname);
  // console.log('window.location.search =====>' + window.location.search);
  return window.location.pathname.split('/');
}

const isRootPage = (sp: string[]): boolean => {
  return sp.length > 2 ? false : true;
}

let level = splitPath();
let isRoot = isRootPage(level);
level[0] = '.';
// console.log('level:' + level + ',isRoot:' + isRoot)

</script>

<style scoped>
.header-boxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75vw;
  margin: auto;
}
/* header left */
.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-left .left {
  flex: 20;
}
.header-left .logo {
  width: 200px;
}
.header-left .middle {
  flex: auto;
  float: right;
}

.header-left .right {
  flex: auto;
  float: right;
}

/* header right */
.header-right {
  display: flex;
}

.header-right .menu {
  white-space: nowrap;
  font-size: 0;
  display: flex;
}

.header-right .menu2 {
  white-space: nowrap;
  font-size: 0;
  display: flex;
}

.menu2 div {
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  width: 247px;
  height: 100px;
}

.menu2 div.hover {
  //background-image: url("@/assets/images/nav_bg.png");
  color: #9bb5cf;
  width: 247px;
}


.menu>div {
  word-wrap: break-word;
  word-break: normal;
  width: 147px;
  height: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.menu .arrow {
  margin-left: 4px;
  width: 14px;
}
.menu .menuChild {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  background: #2b323a;
  border-top: 4px solid #3397fa;
  z-index: 99;
  display: none;
}
.menu .menuChild ul {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 175px;
}
.menu .menuChild ul li {
  height: 35px;
  line-height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
}
.menu .menuChild ul li:hover {
  color: #cbd1d7;
  background: #3d4b56;
}
.menu>div.hover {
  background-image: url("@/assets/images/nav_bg.png");
  width: 147px;
}
.menu>.active .arrow {
  transform: rotate(180deg);
}
.menu>.active .menuChild {
  display: inline-block;
}
div h1 {
  font-family: 'Aleo Regular';
  font-size: 16px;
  line-height: 100px;
  height: 100px;
  text-align: center;
}

div h1.highlight {
  //color: #77fbfc;
  color: #fff;

}

div h1.normal {
  //color: #9bb5cf;
  color: #fff;
  font-size: 17px;
}
</style>