<template>
  <div class="index">
    <MobileIndex v-if="CheckMobileDevice()"></MobileIndex>
    <PcIndex v-else></PcIndex>

  </div>
</template>

<script lang="ts">
import {onMounted, ref, nextTick} from "vue"

import PcIndex from "@/views/pc/index/index.vue"
import MobileIndex from "@/views/mobile/index/index.vue";
import { CheckMobileDevice } from "@/utils/handle";
import CCHeader from "@/components/CCHeader.vue";
import MobileHeader from "@/components/mobile/CCHeader.vue";
// import router from "@/router";

export default {
  components: {MobileHeader, CCHeader, PcIndex, MobileIndex},
  setup() {

    onMounted(() => {
    })
    return {
      CheckMobileDevice,
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.sticky-container {
  overflow: hidden;
  background: #f6f6f6;
  width: 100%;
}

.sticky-container p {
  font-size: 20px;
  margin: 20px;
}

.sticky {
  background: rgb(38, 45, 51);
  align-items: center;
}
.index {
  font-family: "Aleo Regular";
  position: relative;
  .game_img {
    max-width: 75%;
    margin: 80px auto 60px;
    img {
      width: 100%;
    }
  }
  .big-title {
    color: #000;
    span {
      padding: 0 40px;
      font-size: 36px;
      position: relative;
      line-height: 90px;
      height: 90px;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 12px;
        background: #30affd;
        border-radius: 2px;
      }
    }

  }
}

</style>
