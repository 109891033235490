<template>
  <div class="team-page">
    <div class="big-title">
      <span>TEAM</span>
    </div>
    <div class="team-introduce">
      Whales Entertainment, based in Los Angeles, is a passionate game developer and publisher co-founded by game enthusiasts Hanshi 'Hans' Li and Yue 'Luna' Ming. With over a decade of dedicated board game experience and an established Chinese speaking YouTube channel, "Worthy OPawnent", Hans and Luna serve as a significant bridge between the Chinese and international board game communities.
      Their pivotal moment came in 2022, when Hans became the first champion of the World Series of Board Gaming (WSBG). Inspired by this triumph, they founded Whales Entertainment to continue their mission of advocating for Chinese board gamers, providing a platform for Chinese designers to refine their creations, and introducing them to the global market.
      Their debut release, "Come on Bite me!" – a family-friendly party game crafted by Chinese designer Weifeng Feng – was well loved by the community. Currently, we are developing a tactical skirmish tabletop game called "Win or Die," which is expected to be launched on Gamefound and released by the end of 2024. With every roll of the dice, Whales Entertainment is committed to change the stereotypes of “Made in China” to “Create in China”.
    </div>
    <ul class="team-member">
      <li>
        <img src="@/assets/images/pc/hans.png" class="avatar" alt="">
        <div class="name">Hans</div>
      </li>
      <li>
        <img src="@/assets/images/pc/luna.png" class="avatar" alt="">
        <div class="name">Luna</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.team-page {
  background: url("@/assets/images/pc/team_bg.png") no-repeat;
  background-size: 100% 100%;
  font-family: "Aleo Regular";
  padding-bottom: 50px;
  .big-title {
    color: #000;
    span {
      padding: 0 40px;
      font-size: 36px;
      position: relative;
      line-height: 90px;
      height: 90px;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 12px;
        background: #30affd;
        border-radius: 2px;
      }
    }

  }
  .team-introduce {
    font-size: 22px;
    color: #070d0c;
    width: 80%;
    margin: 50px auto 30px;
    line-height: 30px;
    text-align: left;
  }
  .team-member {
      font-size: 26px;
      color: #444;
      display: flex;
      align-items: center;
      justify-content: center;
      > li {
        width: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .avatar {
          width: 140px;
          margin-bottom: 10px;
        }
      }
  }
}
</style>
