<script setup lang="ts">
import Vue, {ref} from 'vue';
import Toast from '@/components/Toast.vue'
import axios from "axios";

let email = ref('')
let comments = ref('')
let refShowModal = ref(false)

const isEmail = (s) => {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/.test(s)
}
const OnSubscribe = () => {
  const valEmail = email.value;

  if (valEmail.length == 0) {
    showFailed('Please enter your email address')
    return
  }

  if (valEmail.length > 64) {
    showFailed('Please enter your email address')
    return
  }

  if (!isEmail(valEmail)) {
    showFailed('Please enter correct email address')
    return
  }

  if (comments.value.length == 0) {
    showFailed('Please enter your suggestions or comments')
    return
  }

  let jsStr = localStorage.getItem('ccenv') || '{}'
  let ccenv = JSON.parse(jsStr)
  if (ccenv.ss !== undefined && new Date().getTime() - ccenv.ss < 1000 * 10) {
    showSucc()
    return
  }

  axios.post("/api/message/add", {
    "email": valEmail,
    // "name": valNames,
    "comments": comments.value,
  }).then(rsp => {
    console.log(rsp);

    if (rsp.status == 200 && rsp.data['code'] == 0) {
      console.log('subscribe...OK')
      localStorage.setItem('ccenv', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
      comments.value = ''
      email.value = ''
      showSucc()
      return
    }
    showFailed('error')
  })
}

const showSucc = () => {
  refShowModal.value = true
}

const msgText = ref('')
const showToast = ref(false)
const showFailed = (msg: string) => {
  msgText.value = msg
  showToast.value = true
  setTimeout(() => {
    showToast.value = false
  }, 2000)
  refShowModal.value = false
}

</script>

<template>
 <div class="form-box">
  <div class="form">
    <div class="big-title">
      <span>CONTACT</span>
    </div>
    <div class="introduce-text">
      Welcome to leave us your suggestions and comments. We will keep your contact information strictly confidential, We will send you the latest news about the game by email:
    </div>
    <div class="input-box">
      <input type="text" placeholder="Please enter your email address" v-model="email">
    </div>
    <div class="textarea-box">
      <textarea placeholder="Please enter your suggestions or comments" v-model="comments"></textarea>
    </div>
    <div class="submit" @click="OnSubscribe">Submit</div>
  </div>
 </div>
  <Toast :msg-text="msgText" v-if="showToast"></Toast>
  <teleport to="body">
    <div class="modal-overlay" v-if="refShowModal">
      <div class="modal">
        <header class="modal-header">
          <button class="close" @click="refShowModal = false"></button>
          <button class="confirm" @click="refShowModal = false"></button>
        </header>
      </div>
    </div>
  </teleport>
</template>

<style scoped lang="less">
.form-box {
  width: 100%;
  background: #f6f6f6;
  color: #8b8b8b;
  overflow: hidden;
  .form {
    width: 50%;
    margin: auto;
    position: relative;
  }
  .big-title {
    color: #000;
    span {
      padding: 0 40px;
      font-size: 36px;
      position: relative;
      line-height: 90px;
      height: 90px;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 12px;
        background: #30affd;
        border-radius: 2px;
      }
    }

  }
  .introduce-text {
    font-size: 24px;
    color: #505050;
    text-align: left;
    line-height: 30px;
    margin-top: 25px;
  }
  .input-box {
    width: 100%;
    height: 65px;
    background: #fff;
    display: flex;
    margin-top: 40px;
    padding: 0 20px;
    border-radius: 10px;
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Aleo Regular";
      font-size: 20px;
    }
  }
  .textarea-box {
    width: 100%;
    height: 240px;
    margin-top: 20px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 10px;
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Aleo Regular";
      font-size: 20px;
      line-height: 30px;
      resize: none;
    }
  }
  .submit {
    background: #30affe;
    color: #fff;
    width: 240px;
    height: 55px;
    line-height: 55px;
    margin: 40px auto;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
  }
}
.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("@/assets/images/pc/confirm_bg.png") #252d46;
  background-size: 1598px 724px;
  width: 1598px;
  height: 724px;
}

.modal-header h2 {
  margin: 0 0 10px;
}

.modal-header .close {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  background-image: url("@/assets/images/close.png");
  background-size: 52px 54px;
  width: 52px;
  height: 54px;
  right: -15px;
  top: -15px;
}

.modal-header .confirm {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  background-image: url("@/assets/images/confirm.png");
  background-size: 437px 124px;
  width: 437px;
  height: 124px;
  top: 500px;
  left: 980px;
}

.modal-content {
  margin: 10px 0;
}
</style>