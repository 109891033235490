<script setup lang="ts">
import Vue, {ref} from 'vue';
import axios from "axios";
import Toast from '@/components/Toast.vue'

let email = ref('')
let comments = ref('')
let refShowModal = ref(false)

const isEmail = (s) => {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/.test(s)
}
const OnSubscribe = () => {
  const valEmail = email.value;

  if (valEmail.length == 0) {
    showFailed('Please enter your email address')
    return
  }

  if (valEmail.length > 64) {
    showFailed('Please enter your email address')
    return
  }

  if (!isEmail(valEmail)) {
    showFailed('Please enter correct email address')
    return
  }
  if (comments.value.length == 0) {
    showFailed('Please enter your suggestions or comments')
    return
  }
  let jsStr = localStorage.getItem('ccenv') || '{}'
  let ccenv = JSON.parse(jsStr)
  if (ccenv.ss !== undefined && new Date().getTime() - ccenv.ss < 1000 * 10) {
    showSucc()
    return
  }

  axios.post("/api/message/add", {
    "email": valEmail,
    // "name": valNames,
    "comments": comments.value,
  }).then(rsp => {
    console.log(rsp);

    if (rsp.status == 200 && rsp.data['code'] == 0) {
      console.log('subscribe...OK')
      localStorage.setItem('ccenv', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
      comments.value = ''
      email.value = ''
      showSucc()
      return
    }
    showFailed('error')
  })
}

const showSucc = () => {
  refShowModal.value = true
}
const msgText = ref('')
const showToast = ref(false)
const showFailed = (msg:string) => {
  msgText.value = msg
  showToast.value = true
  setTimeout(() => {
    showToast.value = false
  }, 2000)
  refShowModal.value = false
}
</script>

<template>
 <div class="form-box">
   <div class="big-title">
     <span>CONTACT</span>
   </div>
   <div class="introduce-text">
     Welcome to leave us your suggestions and comments. We will keep your contact information strictly confidential, We will send you the latest news about the game by email:
   </div>
   <div class="input-box">
     <input type="text" placeholder="Please enter your email address" v-model="email">
   </div>
   <div class="textarea-box">
     <textarea placeholder="Please enter your suggestions or comments" v-model="comments"></textarea>
   </div>
   <div class="submit" @click="OnSubscribe">Submit</div>
   <teleport to="body">
     <div class="modal-overlay" v-if="refShowModal">
       <div class="modal">
         <header class="modal-header">
           <button class="close" @click="refShowModal = false"></button>
           <button class="confirm" @click="refShowModal = false"></button>
         </header>
       </div>
     </div>
   </teleport>
   <Toast :msg-text="msgText" v-if="showToast"></Toast>

 </div>
</template>

<style scoped lang="less">
.form-box {
  padding: 0 .5rem;
  background: #fdfdfd;
  color: #8b8b8b;
  overflow: hidden;
  .big-title {
    color: #000;
    span {
      padding: 0 .8rem;
      font-size: .7rem;
      position: relative;
      line-height: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .2rem;
        background: #30affd;
        border-radius: 0.04rem;
      }
    }

  }
  .introduce-text {
    font-size: .6rem;
    color: #505050;
    text-align: left;
    line-height: .9rem;
    margin-top: .6rem;
  }
  .input-box {
    width: 100%;
    height: 1.8rem;
    display: flex;
    margin-top: .4rem;
    border-radius: .1rem;
    background: #f6f6f6;
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Aleo Regular";
      font-size: .55rem;
      background: #f6f6f6;
      padding: 0 .4rem;
    }
  }
  .textarea-box {
    width: 100%;
    height: 4.9rem;
    margin-top: .4rem;
    border-radius: .2rem;
    padding: .6rem 0;
    background: #f6f6f6;
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Aleo Regular";
      font-size: .55rem;
      line-height: .7rem;
      padding: 0 .4rem;
      resize: none;
      background: #f6f6f6;
    }
  }
  .submit {
    background: #30affe;
    color: #fff;
    width: 6rem;
    height: 1.4rem;
    line-height: 1.4rem;
    margin: .8rem auto;
    font-size: .6rem;
    text-align: center;
  }
}
.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("@/assets/images/mobile/confirm_bg.png");
  background-size: 1598px 724px;
  width: 1598px;
  height: 724px;
}

.modal-header h2 {
  margin: 0 0 10px;
}

.modal-header .close {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  background-image: url("@/assets/images/close.png");
  background-size: 52px 54px;
  width: 52px;
  height: 54px;
  right: -15px;
  top: -15px;
}
.modal-header .confirm {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  background-image: url("@/assets/images/confirm.png");
  background-size: 437px 124px;
  width: 437px;
  height: 124px;
  top: 500px;
  left: 980px;
}
.modal-content {
  margin: 10px 0;
}
</style>