// 判断是否为移动端

export function CheckMobileDevice() {
    const userAgent = navigator.userAgent;
    const mobileAgents = ['Android', 'iPhone', 'Symbian', 'WindowsPhone', 'iPod', 'BlackBerry', 'Windows CE'];
    let is_mobile = false
    mobileAgents.map((value) => {
        if (userAgent.indexOf(value) > -1) {
            is_mobile = true
        }
    })
    return is_mobile
}

export function isValidEmailSuffix(email: string) {
    // 正则表达式匹配常见的域名后缀
    const validSuffixRegex = /\.(com|net|org|edu|gov)$/i;

    // 提取邮箱地址中的域名后缀部分
    const suffix = email.split('@')[1];

    // 进行匹配
    return validSuffixRegex.test(suffix);
}