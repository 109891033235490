<template>
  <div id="sel1">
    <CCSwiper :bannerSwiperList="bannerSwiperList"/>
  </div>
  <!-- products -->
  <div id="sel2">
    <CCProducts/>
  </div>

  <!-- news -->
  <div id="sel3">
    <CCNews />
  </div>
  <!-- team -->
  <div id="sel4">
    <CCTeam />
  </div>

  <!--  form  -->
<!--  <CCForm></CCForm>-->

  <!-- CCPartner -->
  <CCPartner></CCPartner>
  <!-- contact -->
  <CCContact />
</template>

<script>
import {onMounted, ref} from "vue"
import CCSwiper from "@/components/CCSwiper.vue"
import CCForm from "@/views/mobile/index/components/form.vue"
import CCPartner from "@/views/mobile/index/components/partner.vue"
import CCNews from "@/views/mobile/index/components/news.vue";
import CCProducts from "@/views/mobile/index/components/products.vue";
import CCTeam from "@/views/mobile/index/components/team.vue";
import CCContact from "@/views/mobile/index/components/contact.vue";
export default {
  components: {CCContact, CCTeam, CCProducts, CCNews, CCSwiper, CCForm, CCPartner},
  setup() {
    onMounted(() => {
    });
    const bannerSwiperList = [
      {
        href: 'https://www.kickstarter.com/projects/1486633989/win-or-die',
        bannerImg: 'pc/swiper_img1.png'
      },
      {
        href: 'https://www.kickstarter.com/projects/1486633989/win-or-die',
        bannerImg: 'pc/swiper_img2.png'
      }
    ]
    return {
      bannerSwiperList
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.big-title {
  color: #000;
  letter-spacing: 1px;
  span {
    padding: 0 40px;
    font-size: 36px;
    position: relative;
    line-height: 90px;
    height: 90px;
    display: inline-block;
    font-family: "Aleo Regular";
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 12px;
      background: #30affd;
      border-radius: 2px;
    }
  }

}

</style>
