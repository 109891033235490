export default {
    namespaced: true,
    state: () => ({
        data: localStorage.getItem('ccenv') || '{}',
    }),
    mutations: {
        setEnv(state, data) {
            state.data = data
            localStorage.setItem('ccenv', data)
        }
    },
    actions: {}
}