<template>
  <div class="product-info">
    <div class="big-title">
      <span>PRODUCTS</span>
    </div>
    <ul class="product-shopping">
      <li v-for="(item,index) in listData" :key="index" :class="[productIndex === index ? 'active' : '']" @click="handleProduct(index)">
        <div class="box">
          <div class="icon">
            <img :src="require('@/assets/images/'+(productIndex === index ? item.iconActive : item.icon))" alt="">
          </div>
          <div class="text">{{ item.title }}</div>
        </div>
      </li>
    </ul>
    <div class="product-img">
      <div class="game-img" v-if="productIndex == 0 || productIndex == 1">
        <div class="top-img">
          <div class="left-img">
            <img v-lazy="require('@/assets/images/mobile/product_xiyou.png')" @click="jumpPage('/journey-to-the-west-m')" alt="">
            <img v-lazy="require('@/assets/images/mobile/product_caif.png')" @click="jumpPage('/tailor-master-m')" class="left-img-bottom" alt="">
          </div>
          <div class="right-img">
            <img v-lazy="require('@/assets/images/mobile/product_conglin.png')" @click="jumpPage('/come-on-bite-me!-m')" alt="">
            <img v-lazy="require('@/assets/images/mobile/product_wod.png')" @click="jumpPage('/win-or-die-m')" alt="" class="left-img-bottom">
          </div>
        </div>
        <div class="bottom-img">
          <div class="left-img">
            <img v-lazy="require('@/assets/images/mobile/product_witness.png')" @click="jumpPage('/safari-witness-m')" alt="">
          </div>
          <div class="right-img" v-if="productIndex == 0">
            <img v-lazy="require('@/assets/images/mobile/product_aigame.png')" @click="jumpPage('/mobileAiCard')"  alt="">
          </div>
        </div>
      </div>
      <div v-else>
        <img v-lazy="require('@/assets/images/mobile/product_elc.png')" @click="jumpPage('/mobileAiCard')" alt="" style="width: 100%;" >
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { ref, watch } from "vue";

import { useStore } from 'vuex';
import { useRouter } from "vue-router"

export default {
  name: "CCProducts",
  components: {
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let productIndex = ref(0)

    watch(() => store.state.productIndex, (newVal, oldVal) => {
      productIndex.value = newVal
    })
    const handleProduct = (index) => {
      productIndex.value = index
    }
    const listData = [
      {
        title: 'ALL GAMES',
        icon: "mobile/product_all.png",
        iconActive: "mobile/product_all2.png"
      },
      {
        title: 'BOARD GAME',
        icon: "mobile/product_board.png",
        iconActive: "mobile/product_board2.png"
      },
      {
        title: 'DIGITAL GAME',
        icon: "mobile/product_game.png",
        iconActive: "mobile/product_game2.png"
      },
    ]
    const jumpPage = (path) => {
      router.push({
        path
      })
    }
    return {
      listData,
      productIndex,
      handleProduct,
      jumpPage
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.product-info {
  font-family: "Aleo Regular";
  padding: .4rem .5rem;
  margin: auto;
  .big-title {
    color: #000;
    span {
      padding: 0 .8rem;
      font-size: .7rem;
      position: relative;
      line-height: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .2rem;
        background: #30affd;
        border-radius: 0.04rem;
      }
    }

  }
  .product-shopping {
    font-size: .3rem;
    box-shadow: .04rem .04rem .04rem .04rem #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: .8rem .3rem;
    margin-top: 1.6rem;
    li {
      width: 33.3%;
      position: relative;
      cursor: pointer;
      height: 1.8rem;
      border-right: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #505050;
        justify-content: center;
        .icon {
          //width: .9rem;
          height: 1.2rem;
          img {
            width: 1rem;
            max-height: 100%;
          }
        }
        .text {
          font-size: .35rem;
          margin-top: .2rem;
        }
      }
      &.active {

        .box {
          content: '';
          position: absolute;
          left: 50%;
          top: -1.25rem;
          transform: translateX(-50%);
          background: #30affe;
          color: #fff;
          width: 88%;
          height: 4.3rem;
          z-index: 9;
          border-radius: .08rem;
        }
      }
      &:first-child {
        .icon {
          img {
            width: 1.2rem;
          }
        }
      }
      &:last-child {
        border: none;
        .icon {
          img {
            width: 1.4rem;
          }
        }
      }
    }

  }
  .product-img {
    max-width: 100%;
    margin: 1rem auto .6rem;
    .game-img {
      .top-img, .bottom-img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.bottom-img {
          margin-top: .5rem;
        }
        .left-img-bottom {
          margin-top: .55rem;
        }
        .left-img {
          width: 48%;
        }
        .right-img {
          width: 49%;
        }
        img {
          width: 100%;
          height: 7.606rem;
        }
      }
    }

  }
}
</style>