<template>
  <div class="mySwiper">
    <swiper
        :class="CheckMobileDevice() ? 'mobileSwiper' : 'pcSwiper'"
        :autoplay="{delay: 2500,stopOnLastSlide:false,disableOnInteraction: true}"
        :slides-per-view="1"
        :space-between="0"
        :loop="true"
        :pagination="pagination"
        :navigation="{nextEl: '.swiper-button-next',prevEl: '.swiper-button-prev'}"
        :modules="modules"
    >
      <swiper-slide v-for="(item, index) in swiperList" :key="index">
        <img @click="jumpPage(item.href, item.point)" :src="require('@/assets/images/'+item.bannerImg)" style="width: 100%;">
      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination" ></div>
    </swiper>

  </div>

</template>
<script setup>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import {reactive, ref} from "vue";

import {Autoplay} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import '../assets/styles/swiper.css';

// import required modules
import {Pagination, Navigation} from 'swiper/modules';

import { useRouter } from "vue-router"
const router = useRouter()


import {onMounted, defineProps, toRefs, watch} from "vue";
import { CheckMobileDevice } from ".././utils/handle";
let swiperList = reactive([])
if(CheckMobileDevice()) {
  swiperList = [

    {
      href: '/win-or-die-m',
      bannerImg: 'mobile/swiper1.jpg',
      point: 'banner-win',  // 埋点名
    },
    {
      href: '/safari-witness-m',
      bannerImg: 'mobile/swiper6.jpg',
      point: 'banner-safari',  // 埋点名
    },
    {
      href: '/tailor-master-m',
      bannerImg: 'mobile/swiper4.jpg',
      point: 'banner-tailor',  // 埋点名

    },
    {
      href: '/come-on-bite-me!-m',
      bannerImg: 'mobile/swiper2.jpg',
      point: 'banner-come',  // 埋点名
    },
    {
      href: '/mobileAiCard',
      bannerImg: 'mobile/swiper3.jpg',
      point: 'banner-aiCard',  // 埋点名
    },

    {
      href: '/journey-to-the-west-m',
      bannerImg: 'mobile/swiper5.jpg',
      point: 'banner-journey',  // 埋点名

    }
  ]
}else {
  swiperList = [

    {
      href: '/win-or-die',
      bannerImg: 'pc/swiper1.jpg',
      point: 'banner-win',  // 埋点名

    },
    {
      href: '/safari-witness',
      bannerImg: 'pc/swiper6.jpg',
      point: 'banner-safari',  // 埋点名

    },
    {
      href: '/tailor-master',
      bannerImg: 'pc/swiper4.jpg',
      point: 'banner-tailor',  // 埋点名

    },

    {
      href: '/come-on-bite-me!',
      bannerImg: 'pc/swiper2.jpg',
      point: 'banner-come',  // 埋点名

    },
    {
      href: '/aiCard',
      bannerImg: 'pc/swiper3.jpg',
      point: 'banner-aiCard',  // 埋点名

    },

    {
      href: '/journey-to-the-west',
      bannerImg: 'pc/swiper5.jpg',
      point: 'banner-journey',  // 埋点名

    }
  ]

}
const pagination = {
  el: ".swiper-pagination",
  clickable: true,
  type: 'custom',
  renderCustom: function (swiper, current, total) {
    let html = ''
    for(let i = 0; i<total; i++) {
      if(i === current-1) {
        html+=`<div class="mySwiper-pagination mySwiper-pagination-active"></div>`
      }else {
        html+=`<div class="mySwiper-pagination"></div>`
      }
    }
    return html;
  }
}


const selectSwiper = (e) => {
  // const index = e.target.getAttribute('index')
  // swiper.slideToLoop(index, 1000, false)
}

const jumpPage = (path, point) => {
  router.push({
    path: path,
    query: {
      channel: point
    }
  })
}
onMounted(() => {
})

const modules = [Pagination, Navigation, Autoplay]
</script>
<style lang="less">
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: .9rem;
  color: #999;
}
.swiper-button-prev {
  left: .6rem;
}
.swiper-button-next {
  right: .6rem;
}
.mySwiper {
  .mobileSwiper {
    .swiper-slide, .swiper-slide img {
      width: 100%;
      height: 13rem;
    }
  }
  .pcSwiper {
    .swiper-slide, .swiper-slide img {
      width: 100%;
      vertical-align: top;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    color: #fff;
  }
  .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      .mySwiper-pagination {
        width: 40px;
        height: 8px;
        background: #8e8a89;
        margin: 3px;
        border-radius: 2px;
        &.mySwiper-pagination-active {
          background: #fff;
          width: 60px;
        }
      }
  }

}

</style>
