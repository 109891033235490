<template>
  <div class="blog">
    <div>
      <img
        src="../../assets/images/blog_bg.png"
        alt=""
        style="width: 100%;"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CCBlog"
}
</script>

<style scoped>

</style>