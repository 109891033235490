import {CheckMobileDevice} from '../utils/handle'
import '../../trusted-security-policies';
import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import IndexView from '../views/CCIndex.vue'
// const IndexView = () =>  import('../views/CCIndex.vue')
const winnerTakes = () => import('../views/pc/winnerTakes.vue')
// import winnerTakes from '../views/pc/winnerTakes.vue'
const Adventure = () => import('../views/pc/adventure.vue')
const AiCard = () =>  import('../views/pc/AiCard.vue')
const Tailor =  () => import('../views/pc/tailor.vue')
const WestGame = () =>  import('../views/pc/westGame.vue')
const Wilderness = () =>  import('../views/pc/Wilderness.vue')
const contact = () =>  import('../views/pc/contact.vue')
const mobileWinnerTakes = () =>  import('../views/mobile/winnerTakes.vue')
const mobileAdventure = () =>  import('../views/mobile/adventure.vue')
const mobileAiCard = () =>  import('../views/mobile/AiCard.vue')
const mobileTailor = () =>  import('../views/mobile/tailor.vue')
const mobileWestGame = () =>  import('../views/mobile/westGame.vue')
const mobileWilderness = () =>  import('../views/mobile/Wilderness.vue')
const mobileContact = () =>  import( '../views/mobile/contact.vue')
const Catalogue = () =>  import('../views/ohter/catalogue.vue')
const UserAgreement = () =>  import('../views/protocol/userAgreement.vue')
const PrivacyPolicy = () =>  import('../views/protocol/privacyPolicy.vue')
const manual = () =>  import('../views/ohter/manual.vue')
const unsubscribe =  () =>  import('../views/pc/unsubscribe.vue')
const unsubscribe_m = () =>  import('../views/mobile/unsubscribe.vue')
const unsubscription_successful = () =>  import('../views/pc/unsubscription_successful.vue')
const unsubscription_successful_m = () =>  import('../views/mobile/unsubscription_successful.vue')
const subscribe = () =>  import('../views/pc/subscribe.vue')
const subscribe_m =  () => import('../views/mobile/subscribe.vue')


const routes: Array<RouteRecordRaw> = [
    { path: '/', name: '/', component: IndexView },
    { path: '/index', name: 'index', component: IndexView},
    { path: '/product', name: 'product', component: () => import('../views/product/index.vue')},

    { path: '/win-or-die', name: 'win-or-die',  component: winnerTakes},
    { path: '/come-on-bite-me!', name: 'come-on-bite-me!', component: Adventure},
    { path: '/aiCard', name: 'aiCard', component: AiCard},
    { path: '/tailor-master', name: 'tailor-master', component: Tailor},
    { path: '/safari-witness', name: 'safari-witness', component: Wilderness},
    { path: '/journey-to-the-west', name: 'journey-to-the-west', component: WestGame},
    { path: '/contact', name: 'contact', component: contact},

    { path: '/win-or-die-m', name: 'win-or-die-m', component: mobileWinnerTakes},
    { path: '/come-on-bite-me!-m', name: 'come-on-bite-me!-m', component: mobileAdventure},
    { path: '/mobileAiCard', name: 'mobileAiCard', component: mobileAiCard},
    { path: '/tailor-master-m', name: 'tailor-master-m', component: mobileTailor},
    { path: '/journey-to-the-west-m', name: 'journey-to-the-west-m', component: mobileWestGame},
    { path: '/safari-witness-m', name: 'safari-witness-m', component: mobileWilderness},
    { path: '/catalogue', name: 'catalogue', component: Catalogue },
    { path: '/userAgreement', name: 'userAgreement', component: UserAgreement },
    { path: '/PrivacyPolicy', name: 'PrivacyPolicy', component: PrivacyPolicy },
    { path: '/contact-m', name: 'contact-m', component: mobileContact},

    { path: '/manual', name: 'manual', component: manual },
    // 取消订阅
    { path: '/unsubscribe', name: 'unsubscribe', component: unsubscribe },
    { path: '/unsubscribe_m', name: 'unsubscribe_m', component: unsubscribe_m },
    // 取消订阅成功
    { path: '/unsubscription_successful', name: 'unsubscription_successful', component: unsubscription_successful },
    { path: '/unsubscription_successful_m', name: 'unsubscription_successful_m', component: unsubscription_successful_m },
    // 订阅成功页
    { path: '/subscription_successful/:uuid', name: 'subscription_successful', component: subscribe },
    { path: '/subscription_successful_m/:uuid', name: 'subscription_successful_m', component: subscribe_m },


    // 二级页面
    // 历史遗留路径
    {
        path: '/wod',
        name: 'wod',
        redirect: (to => { return { path: '/' } }),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
})

router.beforeEach((to, from, next) => {
    console.log(to.fullPath)
    const path = to.fullPath.indexOf('?') > -1 ? to.fullPath.split('?')[0]: to.fullPath
     if(CheckMobileDevice()) {
        switch (path) {
            case '/safari-witness':
                next('/safari-witness-m');
                break;
            case '/win-or-die':
                next('/win-or-die-m');
                break;
            case '/come-on-bite-me!':
                next('/come-on-bite-me!-m');
                break;
            case '/aiCard':
                next('/mobileAiCard');
                break;
            case '/tailor-master':
                next('/tailor-master-m');
                break;
            case '/journey-to-the-west':
               next('/journey-to-the-west-m');
                break;
            case '/contact':
                next('/contact-m');
                break;
            case '/unsubscribe':
                next('/unsubscribe_m');
                break;
            case '/unsubscription_successful':
                next('/unsubscription_successful_m');
                break;
            default:
                next()
        }
    }else {
        switch (path) {
            case '/safari-witness-m':
               next("/safari-witness");
                break;
            case '/win-or-die-m':
                next("/win-or-die");
                break;
            case '/come-on-bite-me!-m':
               next("/come-on-bite-me!");
                break;
            case '/mobileAiCard':
               next("/aiCard");
                break;
            case '/tailor-master-m':
               next("/tailor-master");
                break;
            case '/journey-to-the-west-m':
                next("/journey-to-the-west");
                break;
            case '/contact-m':
                next('/contact');
                break;
            case '/unsubscribe_m':
                next('/unsubscribe');
                break;
            case '/unsubscription_successful_m':
                next('/unsubscription_successful');
                break;
            default:
                next()
        }
    }
    next();
});
export default router
