<script lang="ts">
import {Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from "vue";


// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import '@/assets/styles/swiper.css';

// import required modules
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
import {setup} from "vue-class-component";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    }
  }
}

</script>

<template>
  <div class="partner-box">
    <div class="big-title">
      <span>PARTNERS</span>
    </div>
    <div class="partner-swiper">
      <div class="outSwiperBox">
        <swiper
            :autoplay="{delay: 2500,stopOnLastSlide:false,disableOnInteraction: true}"
            :slides-per-view="3"
            :slides-per-group="1"
            :space-between="0"
            :loop="true"
            :navigation="{nextEl: '.swiper-button-next',prevEl: '.swiper-button-prev'}"
            :modules="modules"
            class="inSwiperBox"
        >
          <swiper-slide>
            <div class="cover-img">
              <img v-lazy="require('@/assets/images/mobile/partner_1.png')" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="cover-img">
              <img v-lazy="require('@/assets/images/mobile/partner_2.png')" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="cover-img">
              <img v-lazy="require('@/assets/images/mobile/partner_5.png')" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="cover-img">
              <img v-lazy="require('@/assets/images/mobile/partner_4.png')" alt="">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="cover-img">
              <img v-lazy="require('@/assets/images/mobile/partner_3.png')" alt="">
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev">
          <img src="@/assets/images/pc/partner_arrowr.png" alt="">
        </div>
        <div class="swiper-button-next">
          <img src="@/assets/images/pc/partner_arrowl.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="less">
.partner-box {
  background: #e6e6e6;
  padding: .8rem 0;
  .big-title {
    color: #000;
    span {
      padding: 0 .8rem;
      font-size: .7rem;
      position: relative;
      line-height: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .2rem;
        background: #30affd;
        border-radius: 0.04rem;
      }
    }

  }
  .partner-swiper {
    .outSwiperBox {
      position: relative;
      padding: 0 1.8rem;
      .inSwiperBox {
        height: 2.6rem;
        margin-top: .4rem;
        position: inherit;
      }
    }
    .swiper-button-prev, .swiper-button-next{
      img {
        width: .8rem;
      }
    }
    .swiper-button-prev:after, .swiper-button-next:after {
      display: none;
    }
    .swiper-slide .cover-img {
      width: 100%;
      height: 2.6rem;
      img {
        width: 85%;
        object-fit: contain;
      }
    }
  }
}
</style>