<template>
  <div class="contact">
    <img src="@/assets/images/mobile/logo2.png" alt="" class="company_logo">
    <div class="email_box">
      <div class="email_tips">
        Subscribe for our monthly Newsletter! No spam. Unsubscribe at any time.
      </div>
      <div class="input_box">
        <input type="text" v-model="EmailVal" placeholder="Email Address">
      </div>
      <div class="msgText">{{msgText}}</div>
      <div class="submit" @click="submitEmail">
        Subscribe
      </div>
    </div>
    <div id="sel5">
      <div class="logo">
<!--        <div class="contactUs">-->
<!--          Contact Us:-->
<!--        </div>-->
        <img src="@/assets/images/contact_fb.png" alt="" @click="JumpContack(1)">
        <img src="@/assets/images/contact_tw.png" alt="" @click="JumpContack(2)">
        <img src="@/assets/images/contact_bd.png" alt="" @click="JumpContack(3)">
        <img src="@/assets/images/contact_yt.png" alt="" @click="JumpContack(4)">
      </div>
      <div class="email">
        <img class="ico" src="@/assets/images/mail.png">
        <a href="mailto://info@whales-entertainment.com" class="url">info@whales-entertainment.com</a>

      </div>
      <div class="copyright">
        © 2024 Whales Entertainment. All Rights Reserved.
      </div>
      <ul class="protocol" v-if="isShowAgreement">
        <li @click="jumpPage('UserAgreement')">User Agreement</li>
        <li @click="jumpPage('PrivacyPolicy')">Privacy Policy</li>
      </ul>
    </div>
    <teleport to="body">
      <div class="modal-overlay" v-if="refShowModal">
        <div class="modal">
          <div class="modal-header">
            <img src="@/assets/images/mobile/confirm_bg.png" alt="" class="confirm_bg">
            <div class="content">
              <div class="title">
                Almost Ready!
              </div>
              <div class="info">
                <p>We have sent you a message with a personal confirmation link.</p>
                <p>Please check your email and follow the instructions found in our message.</p>
              </div>
              <div class="confirm_btn" @click="refShowModal = false">CLOSE</div>

            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {ref} from "vue";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import { event } from "vue-gtag";

const router = useRouter();
const jumpPage =  (path: string) => {
  router.push({
    path: path
  })
}
const path = router.currentRoute.value.fullPath
const isShowAgreement = (path == '/mobileAiCard')
const isEmail = (s) => {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/.test(s)
}
const JumpContack = (type: number) => {
  let url  = ''
  switch (type) {
    case 1:
      url = 'https://www.facebook.com/people/Whales-Entertainment/100094886750896/'
      break;
    case 2:
      url = 'https://twitter.com/whalesentertain'
      break;
    case 3:
      url = 'https://discord.gg/tBWdKjgCee'
      break;
    case 4:
      url = 'https://www.youtube.com/@WhalesEntertainment'
      break;
  }
  window.open(url)
}

const EmailVal = ref('')
const submitEmail = () => {
  if (EmailVal.value.length == 0) {
    showFailed('Invalid! Please enter an email address.')
    return
  }
  if (!isEmail(EmailVal.value)) {
    showFailed('Invalid! Please enter correct email address')
    return
  }
  let jsStr = localStorage.getItem('ccenv2') || '{}'
  let ccenv = JSON.parse(jsStr)
  if (ccenv.ss !== undefined && new Date().getTime() - ccenv.ss < 1000 * 10) {
    showSucc()
    return
  }
  axios.post("/api/subscribe/add", {
    "email": EmailVal.value,
  }).then(rsp => {
    console.log(rsp)
    if (rsp.status == 200 && rsp.data['code'] == 0) {
      console.log('subscribe...OK')
      EmailVal.value = ''
      msgText.value = ''
      showSucc()
      event('subscribe', {
        button_name: 'email_subscribe'
      });
      localStorage.setItem('ccenv2', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
      return
    }
    showFailed('error')
  })
}

const msgText = ref('')
let refShowModal = ref(false)
const showFailed = (msg: string) => {
  msgText.value = msg
  refShowModal.value = false
}
const showSucc = () => {
  refShowModal.value = true
}
</script>

<style scoped lang="less">
.contact {
  background: #252c32;
  color: #fcfcfc;
  padding: 1.8rem 0 .9rem;
  .company_logo {
    width: 5.6rem;
    margin: auto;
  }
  .email_box {
    width: 13rem;
    margin: .8rem auto 0;
    font-family: "Aleo Regular";

    .email_tips {
      font-size: 0.7rem;
      line-height: 1.05rem;
    }
    .input_box {
      width: 13rem;
      height: 2rem;
      padding: 0 .6rem;
      display: flex;
      justify-content: center;
      background: #fff;
      margin: .8rem 0 0;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        font-size: 0.7rem;
        //color: #999999;
        color: #000;
      }
    }
    .msgText {
      font-size: .6rem;
      color: red;
      margin-top: .5rem;
    }
    .submit {
      width: 6rem;
      height: 2rem;
      background: #30AFFE;
      border-radius: 0.05rem;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: .8rem auto 0;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .55rem;
    margin-top: 1.6rem;
    img {
      width: 1.4rem;
      margin-left: 1rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .6rem;
    .ico {
      width: .9rem;
    }
    .url {
      color: #fcfcfc;
      font-style: normal;
      font-weight: 500;
      margin-left: .4rem;
      font-size: .62rem;
      line-height: normal;
    }
  }
  .copyright {
    font-size: .55rem;
    letter-spacing: .04rem;
    margin-top: .6rem;
  }

}

.protocol {
  color: #fff;
  margin-top: .5rem;
  li {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin-right: .5rem;
    font-size: .6rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);


  .modal {
    position: absolute;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .confirm_bg {
    width: 90%
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #252d46;
    img {
      width: 17.25rem;
      margin: auto;
    }
    .content {
      width: 100%;
      position: absolute;
      top: 11.6rem;
      left: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-family: "Aleo Regular";
      .title {
        color: #fff;
        font-size: .9rem;
        padding:  0 .3rem;
        font-weight: bold;
        letter-spacing: .05rem;
      }
      .info {
        color: #C7C7C7;
        font-size: 0.65rem;
        padding:  0 1.2rem;
        line-height: 1.05rem;
        margin-top: .6rem;
        p {
          &:first-child {
            margin-bottom: .4rem;
          }
        }
      }

      .confirm_btn {
        color: #FFFFFF;
        width: 5.95rem;
        height: 2.05rem;
        line-height: 2.05rem;
        text-align: center;
        cursor: pointer;
        background: #30AFFE;
        font-size: 0.7rem;
        letter-spacing: .05rem;
        margin: .8rem auto 0;
      }
    }
  }

  .modal-content {
    margin: 10px 0;
  }
}




</style>