<template>
  <div class="header-boxs">
    <div class="header-left" @click="OnClick($event, 1)">
      <img src="@/assets/images/log2.png" class="logo" alt="">
    </div>
    <div class="header-right">
      <div class="menu" @click="showMenuList(true)">
        <img src="@/assets/images/mobile/menu.png" alt="" class="menu-icon">
      </div>
    </div>
  </div>
  <div class="model-box" v-if="menuListState">
    <div class="model-bg" @click="showMenuList(false)">
    </div>
    <div class="header-menu-fixed">
      <div class="menu-top">
        <img src="@/assets/images/log2.png" class="logo" alt="">
        <img src="@/assets/images/mobile/close.png" class="close" @click="showMenuList(false)" alt="">
      </div>
      <ul class="menu-list">
        <li>
          <div class="menu-item" @click="OnClick($event, 1)">
            <span>HOME</span>
            <img src="@/assets/images/mobile/arrow1.png" alt="">
          </div>
        </li>
        <li>
          <div class="menu-item">
            <span>PRODUCTS</span>
            <img src="@/assets/images/mobile/arrow1.png" alt="">
          </div>
          <div class="sub-menu-list">
            <div class="sub-menu-item" @click="OnClick($event, 2, 1)">
              <span>Board Games</span>
              <img src="@/assets/images/mobile/arrow2.png" alt="">
            </div>
            <div class="sub-menu-item" @click="OnClick($event, 2, 2)">
              <span>Digital Games</span>
              <img src="@/assets/images/mobile/arrow2.png" alt="">
            </div>
          </div>
        </li>
        <li>
          <div class="menu-item" @click="OnClick($event, 3)">
            <span>NEWS</span>
            <img src="@/assets/images/mobile/arrow1.png" alt="">
          </div>
        </li>
        <li>
          <div class="menu-item" @click="OnClick($event, 4)">
            <span>TEAM</span>
            <img src="@/assets/images/mobile/arrow1.png" alt="">
          </div>
        </li>
        <li>
          <div class="menu-item" @click="JumpContact()">
            <span>CONTACT</span>
            <img src="@/assets/images/mobile/arrow1.png" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, defineEmits, ref} from "vue";
import { useRouter } from "vue-router"
import { useStore } from 'vuex';
const menuListState = ref(false)
const store = useStore();
const router = useRouter();
const emit = defineEmits(['clickMenu', 'clickChildMenu'])

const OnEnter = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('normal')
    event.target.children[0].classList.add('highlight')
  }
  event.target.classList.add('hover')
  event.target.classList.add('active')
}

const OnLeave = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('highlight')
    event.target.children[0].classList.add('normal')
  }
  event.target.classList.remove('hover')
  event.target.classList.remove('active')
}

const OnClick = (event, id, navIndex = 0) => {
  emit('clickMenu', id)
  menuListState.value = false

  if(router.currentRoute.value.fullPath == '/'){
    // 首页直接跳转到锚点
    emit('clickMenu', id)
  }else {
    // 其他页面跳转到首页再定位锚点
    router.replace({
      path: '/index'
    })
    setTimeout(() => {
      emit('clickMenu', id)
    }, 400)
  }
  store.commit('setProductIndex', Number(navIndex))
}
const JumpContact = () => {
  menuListState.value = false
  router.push({
    path: '/contact-m'
  })
}
const OnClickChild = (event, uri) => {
  emit('clickChildMenu', uri)
}

const showMenuList = (state: boolean) => {
  menuListState.value = state
}
const splitPath = () => {
  // console.log('window.location.pathname =====>' + window.location.pathname);
  // console.log('window.location.search =====>' + window.location.search);
  return window.location.pathname.split('/');
}

const isRootPage = (sp: string[]): boolean => {
  return sp.length > 2 ? false : true;
}

let level = splitPath();
let isRoot = isRootPage(level);
level[0] = '.';
// console.log('level:' + level + ',isRoot:' + isRoot)

</script>

<style scoped lang="less">
.header-boxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  margin: auto;
  //height: 1.8rem;
  padding: .4rem 0;
}
/* header left */
.header-left .logo {
  width: 5.6rem;
}


/* header right */
.header-right {
  display: flex;
  .menu-icon {
    width: 1.4rem;
  }
}
.model-box {
  .model-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
    z-index: 8;
  }
  .header-menu-fixed {
    position: fixed;
    background: #252c32;
    top: 0;
    bottom: 0;
    right: 0;
    width: 55%;
    z-index: 9;
    .menu-top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: .1rem solid #434d55;
      padding: .4rem 0;
      .logo {
        width: 6.2rem;
      }
      .close {
        width: 1.4rem;
      }
    }
    .menu-list {
      padding: .3rem 1.3rem;
      color: #fff;
      >li {
        width: 100%;
        .menu-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: .74rem;
          padding: .9rem 0;
          > img {
            width: .23rem;
          }
        }
        .sub-menu-list {
          padding-left: .6rem;
          .sub-menu-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .6rem;
            color: #fff;
            line-height: 1.8rem;
            img {
              width: .16rem;
            }
          }
        }
      }
    }
  }
}


</style>