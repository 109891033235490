import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  "sticky-container": "",
  class: "sticky-container"
}
const _hoisted_3 = {
  key: 0,
  class: "sticky",
  "sticky-offset": "{top: 0}",
  "sticky-side": "both",
  "on-stick": "onStick",
  "sticky-z-index": "20"
}
const _hoisted_4 = { class: "flexBox" }
const _hoisted_5 = { class: "app-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_CCHeader = _resolveComponent("CCHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_sticky = _resolveDirective("sticky")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.initNextTick)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              ($setup.CheckMobileDevice())
                ? (_openBlock(), _createBlock(_component_MobileHeader, {
                    key: 0,
                    onClickMenu: $setup.OnClickMenu,
                    onClickChildMenu: $setup.OnClickChildMenu
                  }, null, 8, ["onClickMenu", "onClickChildMenu"]))
                : (_openBlock(), _createBlock(_component_CCHeader, {
                    key: 1,
                    onClickMenu: $setup.OnClickMenu,
                    onClickChildMenu: $setup.OnClickChildMenu
                  }, null, 8, ["onClickMenu", "onClickChildMenu"]))
            ])
          ])), [
            [_directive_sticky, true]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}