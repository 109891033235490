import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a22deae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileIndex = _resolveComponent("MobileIndex")!
  const _component_PcIndex = _resolveComponent("PcIndex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.CheckMobileDevice())
      ? (_openBlock(), _createBlock(_component_MobileIndex, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PcIndex, { key: 1 }))
  ]))
}